<template>
    <div>
        <webix-ui :config="ui" v-model="all_properties"></webix-ui>
        <div id="propertyPaging"></div>
    </div>
</template>

<script>
import { productMethods, productComputed} from "@/state/helpers"
//import Swal from "sweetalert2";
export default {
    data:() => ({
        ui: {
            id: 'propertyTable',
            view: 'datatable',
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight:60,
            minColumnWidth: 120,
            rowHeight:40,
            scrollY:false,
            scrollX:true,
            resizeColumn: true,
            select: false,
            pager:{
                template:"{common.prev()} {common.pages()} {common.next()}",
                container:"propertyPaging",
                size:15,
                group:5
            },
            columns:[
                { id: 'actions', header:'Actions', width: 100,  template: function(){
                    return `<div class="tableIcons">
                                <!--<i class="fas fa-binoculars viewAction"></i>&nbsp;-->
                                <i class="fas fa-pen editAction"></i>&nbsp;
                                <i class="fas fa-trash deleteAction"></i>
                            </div>`
                }},
                { id: 'name', header:["Property Name", {content: "textFilter"}], tooltip:false, fillspace: 1},
                { id: 'data_type', header:["Data Type", {content: "selectFilter"}], fillspace: 0.8},
            ],
            on:{

            },
            onClick:{//eslint-disable-next-line
                viewAction: function(id, e, trg) {
                    //set the current row as selected
                    let el = this.$scope.$parent;
                    el.setSelectedProduct(e.row);
                },
                //eslint-disable-next-line
                editAction: function(id, e, trg) {
                    var el = this.$scope.$parent;
                    el.$emit('editProduct', e.row);
                },
                //eslint-disable-next-line
                deleteAction: function(id, e, trg) {
                    var el = this.$scope.$parent;
                    var item = this.getItem(e.row);
                    el.$swal.fire({
                    title: "Are you sure you want to delete " + item.name + "?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!"
                    }).then(result => {
                    if (result.value) {
                        //el.ready = false;
                        el.deleteProductProperty({id: e.row}).then(() => {
                            el.getProperties().then(() => {
                                //el.ready = true;
                            });
                        });
                    }
                });
                }
            },
        }
    }),
    computed:{
        ...productComputed,
    },
    methods:{
        ...productMethods,
    },
    mounted(){},
}
</script>

<style>

</style>